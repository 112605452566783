<template>
  <div class="auth">正在跳转....</div>
</template>

<script>
import store from "@/store";
import { getHashParam } from "@/utils/utils.js";
import { getUserInfoByIdUrl } from "./api";
export default {
  name: "auth",
  data() {
    return {
      data: "",
      search: "",
      path: "",
      userInfo: null,
      result: {},
      code: "",
      winUrl: "",
      fromOfficialAccount: "",
      type: "",
    };
  },
  async created() {
    this.fromOfficialAccount = getHashParam("id");
    this.type = getHashParam("type");
  },
  mounted() {
    if (
      localStorage.getItem("userInfo") &&
      localStorage.getItem("userInfo").id
    ) {
      this.selectRoute();
    } else {
      this.getCode();
    }
  },
  methods: {
    selectRoute() {
      if (this.type == 1) {
        this.$router.replace({
          name: "healthReport",
          query: {
            oKey: getHashParam("oKey"),
            examId: getHashParam("examId"),
          },
        });
      } else if (this.type == 2) {
        this.$router.replace({
          name: "healthHouse-myInfo",
          query: {
            type: this.type,
          },
        });
      } else if (this.type == 9) {
        this.$router.replace({
          name: "bloodPressureReport",
          query: {
            examId: getHashParam("examId"),
          },
        });
      } else {
        this.$router.replace({
          name: "healthHouse-my",
        });
      }
    },
    async getUserInfo(code) {
      let params = {
        code: code,
        fromOfficialAccount: this.fromOfficialAccount,
      };
      let url = getUserInfoByIdUrl;
      let res = await this.$axios.get(`${url}`, { params });
      if (res.code == 200) {
        let userInfo = res.data;
        localStorage.setItem("userInfo", JSON.stringify(userInfo));
        this.$store.commit("setUserInfo", userInfo);
        this.selectRoute();
      }
    },
    getCode() {
      // 非静默授权，第一次有弹框
      this.code = "";
      var local = window.location.href; // 获取页面url
      console.log(local);
      var appid = "wx302152948cafb20e"; //测试wxa7ba0d8f90d0e72c
      this.code = this.getUrlCode().code; // 截取code
      if (this.code == null || this.code === "") {
        // 如果没有code，则去请求
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(
          local
        )}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`;
      } else {
        this.getUserInfo(this.code);
        // 你自己的业务逻辑
      }
    },
    getUrlCode() {
      // 截取url中的code方法
      var url = location.search;
      this.winUrl = url;
      var theRequest = new Object();
      if (url.indexOf("?") != -1) {
        var str = url.substr(1);
        var strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
        }
      }
      return theRequest;
    },
  },
};
</script>

<style lang="less" scoped>
.auth {
  text-align: center;
  font-size: 18px;
  line-height: 24px;
}
</style>
